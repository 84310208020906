import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { answers, guesses } from './words';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faDeleteLeft,
  faShareNodes,
  faQuestionCircle,
  faTimes,
  faTrophy,
  faTurnDown,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import ReactModal from 'react-modal';

ReactModal.setAppElement(document.getElementById('root')!);

const ORIGIN_DATE = 1656043200000;

function choice<T>(values: T[]): T {
  return values[Math.floor(Math.random() * values.length)];
}

const KeyboardRow: React.FC<{ used: { [x: string]: boolean }; letters: string; onClick(letter: string): void; last?: boolean }> = ({
  used,
  letters,
  onClick,
  last,
}) => {
  return (
    <div className='keyboard-row'>
      <div className='spacer' />
      {last ? (
        <div className='letter action' onClick={() => onClick('Backspace')}>
          <FontAwesomeIcon icon={faDeleteLeft} />
        </div>
      ) : null}
      {letters.split('').map((letter) => (
        <div className={'letter ' + (used[letter] ? 'used' : '')} onClick={() => onClick(letter)} key={letter}>
          {letter}
        </div>
      ))}
      {last ? (
        <div className='letter action' onClick={() => onClick('Enter')}>
          <FontAwesomeIcon icon={faTurnDown} style={{ transform: 'rotate(90deg)', marginTop: 5 }} />
        </div>
      ) : null}
      <div className='spacer' />
    </div>
  );
};

const DisplayWord: React.FC<{ word: string; starter?: boolean; previous: string[]; index?: number; invalid?: boolean }> = ({
  word,
  starter,
  previous,
  index,
  invalid,
}) => {
  const letterFirsts: { [x: string]: boolean } = { ' ': true };
  previous.forEach((word) => word.split('').forEach((l) => (letterFirsts[l] = true)));
  return (
    <div className={'display-word' + (starter ? ' starter-word' : ' guess') + (invalid ? ' invalid' : '')}>
      {word
        .padEnd(5, ' ')
        .split('')
        .map((letter, i) => {
          const first = !letterFirsts[letter];
          letterFirsts[letter] = true;
          return (
            <div className={'letter' + (first ? ' first' : '') + (letter === ' ' ? ' blank' : '')} key={i}>
              {letter.toUpperCase()}
            </div>
          );
        })}
      <div className='index'>{index != null ? index + 1 : ''}</div>
    </div>
  );
};

const Keyboard: React.FC<{ words: string[]; onClick(letter: string): void }> = ({ words, onClick }) => {
  const usedLetters: { [x: string]: boolean } = {};
  for (const word of words) {
    for (const letter of word) {
      usedLetters[letter] = true;
    }
  }

  return (
    <div className='keyboard'>
      <KeyboardRow letters='qwertyuiop' used={usedLetters} onClick={onClick} />
      <KeyboardRow letters='asdfghjkl' used={usedLetters} onClick={onClick} />
      <KeyboardRow letters='zxcvbnm' used={usedLetters} onClick={onClick} last />
    </div>
  );
};

function getDay(): number {
  const delta = new Date().getTime() - ORIGIN_DATE;
  return Math.floor(delta / 24 / 3_600_000);
}

function useDay(): number {
  const [day, setDay] = useState(Number(window.location.hash.slice(1)) || getDay());
  useEffect(() => {
    if (!window.location.hash) setInterval(() => setDay(getDay()), 1000);
    window.onhashchange = () => setDay(Number(window.location.hash.slice(1)) || getDay());
  }, []);
  return day;
}

function getWords(day: number): string[] {
  const N = answers.length;
  const N4 = N * N * N * N;

  let n = (day * 84671395342 + 373015513163) % N4;
  for (let i = 0; i < N; i++) {
    n = (n * 84671395342 + 373015513163) % N4;
  }

  const indices: number[] = [];
  for (let i = 0; i < 4; i++) {
    while (indices.includes(n % N)) {
      n = (n * 84671395342 + 373015513163) % N4;
    }
    indices.push(n % N);
    n = Math.floor(n / N);
  }
  return indices.map((i) => answers[i]);
}

function mean(values: number[]) {
  if (!values.length) return 'N/A';
  return Math.round((values.reduce((a, b) => a + b, 0) / values.length) * 10) / 10;
}

function two(x: number) {
  if (x >= 10) return x;
  return `0${x}`;
}

function getRemainingTime(timestamp: number): string {
  const delta = timestamp - new Date().getTime();
  const h = Math.floor(delta / 3_600_000);
  const m = Math.floor(delta / 60_000) % 60;
  const s = Math.floor(delta / 1_000) % 60;
  return `${two(h)}:${two(m)}:${two(s)}`;
}

const Countdown: React.FC<{ timestamp: number }> = ({ timestamp }) => {
  const [remainingTime, setRemainingTime] = useState(getRemainingTime(timestamp));
  useEffect(() => {
    setInterval(() => setRemainingTime(getRemainingTime(timestamp)), 1000);
  }, []);
  return <div className='countdown'>{remainingTime}</div>;
};

const EndScreen: React.FC<{ onShare(): void }> = ({ onShare }) => {
  const currentDay = useDay();
  const [copied, setCopied] = useState(false);

  const scores = JSON.parse(localStorage.scores || '{}');
  const distinctScores: { [x: number]: number } = {};
  for (const v of Object.values(scores) as number[]) distinctScores[v] = (distinctScores[v] || 0) + 1;
  const highScore = Math.max(...Object.values(distinctScores));
  const distinctScores2: number[][] = [];
  for (const [k, v] of Object.entries(distinctScores)) distinctScores2.push([Number(k), v, v / highScore]);

  let currentStreak = 0;
  let maxStreak = 0;
  let streak = 0;
  let last = -1;
  for (const day of Object.keys(scores)
    .map((x) => Number(x))
    .sort((a, b) => a - b)) {
    if (day - 1 === last) streak++;
    else streak = 1;
    last = day;
    maxStreak = Math.max(streak, maxStreak);
    if (day === currentDay) currentStreak = streak;
  }

  function share() {
    setCopied(true);
    setInterval(() => setCopied(false), 1_500);
    onShare();
  }

  return (
    <div id='end-screen'>
      <h2>Statistics</h2>
      <div className='stats'>
        <div className='stat'>
          <div className='number'>{Object.values(scores).length}</div>
          <div className='name'>Played</div>
        </div>
        <div className='stat'>
          <div className='number'>{mean(Object.values(scores))}</div>
          <div className='name'>Average Score</div>
        </div>
        <div className='stat'>
          <div className='number'>{currentStreak}</div>
          <div className='name'>Current Streak</div>
        </div>
        <div className='stat'>
          <div className='number'>{maxStreak}</div>
          <div className='name'>Max Streak</div>
        </div>
      </div>
      <h2>Guess Distribution</h2>
      {distinctScores2.sort().map((data) => {
        const [k, v, pct] = data;
        return (
          <div className='distro-bar' key={k}>
            <div className='number'>{k}</div>
            <div className='bar-wrapper'>
              <div className={'bar' + (k === scores[currentDay] ? ' current' : '')} style={{ width: `${pct * 100}%` }}>
                {v}
              </div>
            </div>
          </div>
        );
      })}
      <div className='next-share'>
        <div className='next'>
          <h2>Next Game</h2>
          <Countdown timestamp={ORIGIN_DATE + (currentDay + 1) * 24 * 3_600_000} />
        </div>
        <div className='share'>
          <h2>Share</h2>
          <button onClick={share}>
            {copied ? (
              <>
                <FontAwesomeIcon icon={faCheck} /> Copied
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faShareNodes} /> Share
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

function isFinished(words: string[]): boolean {
  const letters: { [x: string]: boolean } = {};
  for (const word of words) {
    for (const letter of word.split('')) {
      letters[letter] = true;
    }
  }
  return Object.keys(letters).length === 26;
}

function getShareImage(words: string[]): Promise<Blob> {
  const canvas = document.createElement('canvas');
  canvas.width = 360;
  canvas.height = 60 + 60 * words.length;
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  ctx.fillStyle = 'white';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  const letters: { [x: string]: boolean } = {};
  ctx.font = '48px sans-serif';
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    for (let j = 0; j < 5; j++) {
      const letter = word[j];
      if (letter in letters) ctx.fillStyle = '#607D8B';
      else ctx.fillStyle = '#2196F3';
      letters[letter] = true;
      ctx.fillRect(j * 60 + 32, i * 60 + 32, 56, 56);
      if (i < 4) {
        ctx.fillStyle = 'white';
        const width = ctx.measureText(letter).width;
        ctx.fillText(letter, 60 * j + 60 - width / 2, 60 * i + 77, 56);
      }
    }
  }

  return new Promise((resolve) => canvas.toBlob((blob) => resolve(blob!)));
}

function App() {
  const day = useDay();
  const localGuesses = JSON.parse(localStorage[`guesses-${day}`] || '[]');
  const [word1, word2, word3, word4] = getWords(day);
  const [myGuesses, setMyGuesses] = useState<string[]>(localGuesses);
  const [guess, setGuess] = useState('');
  const currentGuess = useRef('');
  const currentGuesses = useRef<string[]>(localGuesses);
  const setKey = useState(0)[1];
  const [showEndScreen, setShowEndScreen] = useState(false);
  const [showHelp, setShowHelp] = useState(!localStorage.seenHelp);

  const words = [word1, word2, word3, word4, ...myGuesses];
  const finished = isFinished(words);

  useEffect(() => {
    if (finished) {
      const scores = JSON.parse(localStorage.scores || '{}');
      scores[day] = currentGuesses.current.length;
      localStorage.scores = JSON.stringify(scores);
    }
    setShowEndScreen(finished);
  }, [finished]);

  function addLetter(letter: string) {
    const g = currentGuess.current;
    if (letter === 'Enter') makeGuess();
    else if (letter === 'Backspace' && g.length) currentGuess.current = g.substring(0, g.length - 1);
    else if ((g + letter).length <= 5) currentGuess.current = g + letter;
    setGuess(currentGuess.current);
  }

  function makeGuess() {
    if (guesses.includes(currentGuess.current)) {
      currentGuesses.current = currentGuesses.current.concat(currentGuess.current);
      setMyGuesses(currentGuesses.current);
      localStorage[`guesses-${day}`] = JSON.stringify(currentGuesses.current);
      currentGuess.current = '';
      setGuess('');
    }
  }

  useEffect(() => {
    window.onkeydown = (event) => {
      if (event.key === 'Enter') makeGuess();
      if (event.ctrlKey || event.metaKey || event.altKey) return;
      if ('abcdefghijklmnopqrstuvwxyz'.includes(event.key) || event.key === 'Backspace') addLetter(event.key);
    };
    window.onresize = () => setKey(Math.random());
  }, []);

  const headerHeight = 70;
  const keyboardHeight = Math.min(210, window.innerWidth * 0.525);
  const numWords = (finished ? 4 : 5) + myGuesses.length;
  const wordsHeight = window.innerHeight - headerHeight - keyboardHeight - 20;
  const fontSize = Math.min(30, (window.innerWidth - 30) / 13.25, wordsHeight / 2.25 / numWords);

  async function share() {
    const text = `Alphabet Soup #${day} in ${currentGuesses.current.length}\nhttps://alphabet-soup-game.com`;
    const textBlob = new Blob([], { type: 'text/plain' });
    const img = await getShareImage(words);
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
      await navigator.share({ title: 'Alphabet Soup', text, files: [new File([img], 'alphabet-soup.png')] });
    else await navigator.clipboard.write([new ClipboardItem({ 'text/plain': textBlob, 'image/png': await getShareImage(words) })]);
  }

  function closeHelp() {
    setShowHelp(false);
    localStorage.seenHelp = true;
  }

  return (
    <div className='alphabet-soup'>
      <div className='header'>
        <div className='menu-control'>{/*<FontAwesomeIcon icon={faBars} />*/}</div>
        <div className='title'>Alphabet Soup #{day}</div>
        <div className='help'>
          <FontAwesomeIcon icon={faQuestionCircle} onClick={() => setShowHelp(true)} title='Help' />
        </div>
        <div className='scores'>
          <FontAwesomeIcon icon={faTrophy} onClick={() => setShowEndScreen(true)} title='Statistics' />
        </div>
      </div>
      <div className='words-outer-wrapper'>
        <div className='spacer' />
        <div className='words-wrapper' style={{ fontSize }}>
          <div>
            <DisplayWord word={word1} previous={[]} starter />
            <DisplayWord word={word2} previous={[word1]} starter />
            <DisplayWord word={word3} previous={[word1, word2]} starter />
            <DisplayWord word={word4} previous={[word1, word2, word3]} starter />

            {myGuesses.map((guess, i) => (
              <DisplayWord word={guess} previous={[word1, word2, word3, word4, ...(i ? myGuesses.slice(0, i) : [])]} index={i} key={i} />
            ))}
            {!finished ? (
              <DisplayWord
                word={guess}
                previous={[word1, word2, word3, word4, ...myGuesses]}
                index={myGuesses.length}
                invalid={guess.length === 5 && !guesses.includes(guess)}
              />
            ) : null}
          </div>
        </div>
        <div className='spacer' />
      </div>
      <div className='keyboard-wrapper'>
        <Keyboard words={[word1, word2, word3, word4, ...myGuesses]} onClick={addLetter} />
      </div>

      <ReactModal isOpen={showHelp} onRequestClose={closeHelp}>
        <div className='close-modal'>
          <FontAwesomeIcon icon={faTimes} onClick={closeHelp} />
        </div>
        <div className='help-info'>
          <h2>How to Play</h2>
          <span>
            Use every letter in the alphabet in as few words as possible. The game starts with four pre-selected five-letter words, and you
            need to come up with more five-letter words to use up every letter in the alphabet.
          </span>
        </div>
      </ReactModal>
      <ReactModal isOpen={showEndScreen} onRequestClose={() => setShowEndScreen(false)}>
        <div className='close-modal'>
          <FontAwesomeIcon icon={faTimes} onClick={() => setShowEndScreen(false)} />
        </div>
        <EndScreen onShare={share} />
      </ReactModal>
    </div>
  );
}

export default App;
